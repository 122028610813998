import React from "react";
import { graphql, Link as GatsbyLink, useStaticQuery } from "gatsby";
import * as Flive from "@flive/react-kit";
import Brand from "@components/Brand";
import useMenus from "@hooks/useMenus";
import useCompanyInfo from "@hooks/useCompanyInfo";

const {
  Footer: { Section, Title, Content, Paragraph, Menu, MenuLink },
  Link,
  View,
} = Flive;

const { BrandIcon } = Brand;
const getPageURL = (page) => {
  if (!page) return '#'
  return page.homePage ? "/" : `/${page.slug}`;
};

const Footer = () => {
  const menus = useMenus();
  const companyInfo = useCompanyInfo();
  return (
    <View {...style.wrapper}>
      <Section>
        <Content>
          <BrandIcon size="mini-10" />
          <Paragraph>{companyInfo.slogan}</Paragraph>
        </Content>
        <Menu label={companyInfo.name}>
          {menus
            .filter((menu) => menu.submenus.length === 0)
            .map((menu) => {
              return (
                <MenuLink
                  to={getPageURL(menu.page)}
                  linkComponent={GatsbyLink}
                  key={menu.id}
                >
                  {menu.title}
                </MenuLink>
              );
            })}
        </Menu>
        {menus
          .filter((menu) => menu.submenus.length > 0)
          .map((menu) => {
            return (
              <Menu label={menu.title} key={menu.id}>
                {menu.submenus.map((submenu) => {
                  return (
                    <MenuLink
                      to={getPageURL(submenu.page)}
                      key={submenu.id}
                      linkComponent={GatsbyLink}
                    >
                      {submenu.title}
                    </MenuLink>
                  );
                })}
              </Menu>
            );
          })}
        <Content margin={{ xs: { top: "mini-1" }, sm: { top: "none" } }}>
          <Title>Information</Title>
          <Paragraph>
            Numéro de téléphone:{" "}
            <Link href={`tel:${companyInfo.phoneNumber}`}>
              {companyInfo.phoneNumber}
            </Link>
            <br />
            Adresse:{" "}
            <Link
              href={`https://www.google.com/maps?q=${companyInfo.address}`}
              external
            >
              {companyInfo.address}
            </Link>
            <br />
            Email:{" "}
            <Link href={`mailto:${companyInfo.contactEmail}`}>
              {companyInfo.contactEmail}
            </Link>
            <br />
            SIRET: {companyInfo.siret}
            <br />
          </Paragraph>
        </Content>
      </Section>
      <Section>
        <Content textAlign="left">
          <Paragraph>Copyright 2021-{new Date().getFullYear()}</Paragraph>
        </Content>
      </Section>
    </View>
  );
};

const style = {
  wrapper: {
    boxShadow: {
      shadow: "0 -4px 10px 00",
      color: "border",
      opacity: 42,
    },
    padding: { y: "mini-3" },
  },
};

export default Footer;
