import React from "react";
import { graphql, Link as GatsbyLink, useStaticQuery } from "gatsby";
import * as Flive from "@flive/react-kit";
import NavigationDrawer from "@flive/react-navigation-drawer";
import Brand from "@components/Brand";
import useMenus from "@hooks/useMenus";
import burgerMenu from "@images/icons/burger.svg";

const {
  Header: { Menu, MenuLink, MenuDropdown },
  View,
  Grid,
  Icon,
} = Flive;

const getCurrentTab = (pathname) => pathname.split("/")[1];
const getPageURL = (page) => {
  if (!page) return '#'
  return page.homePage ? "/" : `/${page.slug}`;
};

const Header = ({ location }) => {
  const menus = useMenus();
  return (
    <>
      <Grid.Row flexGrow={1} display={{ xs: "flex", md: "none" }}>
        <Grid.Column
          leftOffset={5}
          colWidth={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Brand width="mini-9" to="/" as={GatsbyLink} textDecoration="none" />
        </Grid.Column>
        <Grid.Column
          leftOffset={2}
          colWidth={3}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <NavigationDrawer
            title={
              <Brand
                width="mini-9"
                as={GatsbyLink}
                to="/"
                textDecoration="none"
              />
            }
            linkComponent={GatsbyLink}
            toggle={
              <Icon
                cursor="pointer"
                svg={burgerMenu}
                size="mini-2"
                padding="mini-2"
                textColor="secondaryTitle"
                display="flex"
                alignItems="center"
              />
            }
            menuLinks={menus.map((menu) => ({
              label: menu.title,
              key: menu.id,
              linkComponent: menu.page ? GatsbyLink : null,
              to: menu.page ? getPageURL(menu.page) : null,
              children:
                menu.submenus.length > 0
                  ? menu.submenus.map((submenu) => ({
                      label: submenu.title,
                      key: submenu.id,
                      linkComponent: GatsbyLink,
                      to: getPageURL(submenu.page),
                    }))
                  : null,
            }))}
          />
        </Grid.Column>
      </Grid.Row>
      <View
        display={{ xs: "none", md: "flex" }}
        width={{ md: "layout-30", lg: "layout-40" }}
        margin={{ x: "auto" }}
      >
        <Brand
          alignSelf="center"
          margin={{ left: "mini-2" }}
          as={GatsbyLink}
          to="/"
        />
        <Menu margin={{ left: "mini-2" }}>
          {menus.map((menu) => {
            if (menu.submenus.length > 0) {
              return (
                <MenuDropdown
                  active={menu.submenus.find(
                    (submenu) => getPageURL(submenu.page) === location.pathname
                  )}
                  menuLinks={menu.submenus.map((submenu) => {
                    const url = getPageURL(submenu.page);
                    return {
                      label: submenu.title,
                      key: submenu.id,
                      to: url,
                      linkComponent: GatsbyLink,
                      active: url === location.pathname,
                    };
                  })}
                  key={menu.id}
                >
                  {menu.title}
                </MenuDropdown>
              );
            } else {
              const url = getPageURL(menu.page);
              return (
                <MenuLink
                  key={menu.id}
                  to={url}
                  active={location.pathname === url}
                  linkComponent={GatsbyLink}
                >
                  {menu.title}
                </MenuLink>
              );
            }
          })}
        </Menu>
      </View>
    </>
  );
};

export default Header;
