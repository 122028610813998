/* eslint-disable react/jsx-no-bind */
import React from "react";
import PropTypes from "prop-types";
import { Link as GatsbyLink, graphql, useStaticQuery } from "gatsby";

import * as Flive from "@flive/react-kit";
import NavigationDrawer from "@flive/react-navigation-drawer";

import Footer from "@components/Footer";
import Header from "@components/Header";
import theme from "./theme";

const { View, Text, Layout, FliveProvider } = Flive;

const MainLayout = ({ children, location }) => {
  return (
    <FliveProvider theme={theme}>
      <Layout header={<Header location={location} />} footer={<Footer />}>
        {children}
      </Layout>
    </FliveProvider>
  );
};

MainLayout.propTypes = {
  location: PropTypes.object.isRequired,
};

export default MainLayout;
