const black = "#000000";
const cornflower = "#32419B";
const mineShaft = "#666666";
const darkGray = "#999999";
const dustyGray = "#cccccc";
const winter = "#d5dae3";
const light = "#f4f6f9";
const pearlWhite = "#ffffff";
const teal = "#008080";
const tealShade = "#00CCCC";
const lagoon = "#00DFFF";
const lemon = "#ffff32";
const mint = "#00e18c";
const jade = "#02B773";
const jadeShade = "#e1faea";
const tangerine = "#ffa000";
const tangerineShade = "#ffeccc";
const grapefruit = "#fa5537";
const grapefruitShade = "#feddd7";
const grapefruitDark = "#CE2F12";
const cornflowerShade = "#5667C8";
const cornflowerDark = "#19214D";

const colorToken = {
  cornflower: {
    name: "cornflower",
    color: cornflower,
    contentColor: pearlWhite,
    illustration: "both",
  },
  black: {
    name: "Black",
    color: black,
    contentColor: pearlWhite,
    illustration: "both",
  },
  mineShaft: {
    name: "Mine shaft",
    color: mineShaft,
    contentColor: pearlWhite,
    illustration: "both",
  },
  darkGray: {
    name: "Dark gray",
    color: darkGray,
    contentColor: black,
    illustration: "both",
  },
  dustyGray: {
    name: "Dusty gray",
    color: dustyGray,
    contentColor: darkGray,
    illustration: "both",
  },
  winter: {
    name: "Winter",
    color: winter,
    contentColor: black,
    illustration: "both",
  },
  light: {
    name: "Light",
    color: light,
    contentColor: black,
    illustration: "both",
  },
  pearlWhite: {
    name: "Pearl white",
    color: pearlWhite,
    contentColor: black,
    illustration: "both",
  },
  cornflowerDark: {
    name: "cornflower dark",
    color: cornflowerDark,
    contentColor: pearlWhite,
  },
  cornflower: {
    name: "cornflower",
    color: cornflower,
    contentColor: pearlWhite,
    illustration: "both",
  },
  cornflowerShade: {
    name: "cornflower shade",
    color: cornflowerShade,
    contentColor: black,
  },
  jade: {
    name: "Jade",
    color: jade,
    contentColor: black,
    illustration: "both",
  },
  jadeShade: {
    name: "Jade shade",
    color: jadeShade,
    contentColor: black,
  },
  tangerine: {
    name: "Tangerine",
    color: tangerine,
    contentColor: black,
    illustration: "both",
  },
  tangerineShade: {
    name: "Tangerine shade",
    color: tangerineShade,
    contentColor: black,
  },
  grapefruitDark: {
    name: "Grapefruit dark",
    color: grapefruitDark,
    contentColor: pearlWhite,
  },
  grapefruit: {
    name: "Grapefruit",
    color: grapefruit,
    contentColor: pearlWhite,
    illustration: "both",
  },
  grapefruitShade: {
    name: "Grapefruit shade",
    color: grapefruitShade,
    contentColor: black,
  },
  teal: {
    name: "teal",
    color: teal,
    contentColor: pearlWhite,
    illustration: "both",
  },
  tealShade: {
    name: "teal shade",
    color: tealShade,
    contentColor: black,
  },
  // illustrations only
  lemon: {
    name: "Lemon",
    color: lemon,
    contentColor: black,
    illustration: "only",
  },
  mint: {
    name: "Mint",
    color: mint,
    contentColor: black,
    illustration: "only",
  },
  lagoon: {
    name: "Lagoon",
    color: lagoon,
    contentColor: black,
    illustration: "only",
  },
};

const semanticColor = {
  // primary colors
  primary: { useName: "Primary", ...colorToken.cornflower },
  primaryDark: { useName: "Primary dark", ...colorToken.cornflowerDark },
  secondary: { useName: "Secondary", ...colorToken.teal },

  // text and content
  primaryTitle: { useName: "Primary title", ...colorToken.cornflower },
  secondaryTitle: { useName: "Secondary title", ...colorToken.black },
  primaryContent: { useName: "Primary content", ...colorToken.black },
  secondaryContent: { useName: "Secondary content", ...colorToken.mineShaft },
  tertiaryContent: { useName: "Border", ...colorToken.winter },
  disabledContent: { useName: "Disabled content", ...colorToken.darkGray },
  invertContent: { useName: "Invert text", ...colorToken.pearlWhite },
  inputText: { useName: "Input text", ...colorToken.black },

  // border
  border: { useName: "Border", ...colorToken.winter },
  secondaryBorder: { useName: "Secondary border", ...colorToken.darkGray },

  // background
  primaryBackground: {
    useName: "Primary background",
    ...colorToken.pearlWhite,
  },
  secondaryBackground: { useName: "Secondary background", ...colorToken.light },
  tertiaryBackground: { useName: "Tertiary background", ...colorToken.winter },
  disabledBackground: {
    useName: "Disabled background",
    ...colorToken.dustyGray,
  },
  activeStateBackground: {
    useName: "Active state background",
    ...colorToken.cornflowerShade,
  },
  invertBackground: { useName: "Invert background", ...colorToken.black },

  // messages
  info: { useName: "Info", ...colorToken.cornflower },
  infoShade: { useName: "Info shade", ...colorToken.cornflowerShade },
  infoDark: { useName: "Info dark", ...colorToken.cornflowerDark },
  success: { useName: "Success", ...colorToken.jade },
  successShade: { useName: "Success shade", ...colorToken.jadeShade },
  warning: { useName: "Warning", ...colorToken.tangerine },
  warningShade: { useName: "Warning shade", ...colorToken.tangerineShade },
  danger: { useName: "Danger", ...colorToken.grapefruit },
  dangerShade: { useName: "Danger shade", ...colorToken.grapefruitShade },
  dangerDark: { useName: "Danger dark", ...colorToken.grapefruitDark },

  codeBackground: { useName: "Code background", ...colorToken.black },

  // Secondary colors
  accentColor: {
    useName: "Accent color",
    ...colorToken.teal,
  },
  accentColorShade: {
    useName: "Accent color shade",
    ...colorToken.tealShade,
  },
  primaryIllustrationColor: {
    useName: "Primary illustration color",
    ...colorToken.cornflower,
  },
  secondaryIllustrationColor: {
    useName: "Secondary illustration color",
    ...colorToken.teal,
  },
  thirdIllustrationColor: {
    useName: "Third illustration color",
    ...colorToken.lemon,
  },
  fourthIllustrationColor: {
    useName: "Fourth illustration color",
    ...colorToken.mint,
  },
  fifthIllustrationColor: {
    useName: "Fifth illustration color",
    ...colorToken.lagoon,
  },
};

const theme = {
  common: {
    semanticColor,
  },
};

export default theme;
