import styled from "styled-components";
import { Svg, styledViewProps, viewMixin } from "@flive/react-kit"; // eslint-disable-line import/no-unresolved

export const styledPropsAsObject = styledViewProps.reduce((acc, prop) => {
  acc[prop] = true;
  return acc;
}, {});

const styledBrandIconProps = {
  ...styledPropsAsObject,
  pxHeight: true,
  pxWidth: true,
};

const StyledBrandIcon = styled(Svg).withConfig({
  shouldForwardProp: (prop) => !styledBrandIconProps[prop],
})`
  ${viewMixin}
  ${({ pxHeight, pxWidth }) => `
    height: ${pxHeight}px;
    min-height: ${pxHeight}px;
    max-height: ${pxHeight}px;
    width: ${pxWidth}px;
    min-width: ${pxWidth}px;
    max-width: ${pxWidth}px;
  `}
`;

export default StyledBrandIcon;
