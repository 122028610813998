import { graphql, useStaticQuery } from "gatsby";

export default function useMenus() {
  const { graphcms } = useStaticQuery(
    graphql`
      query Menus {
        graphcms {
          menus {
            id
            title
            page {
              slug
              homePage
            }
            submenus {
              ... on GRAPHCMS_Submenu {
                id
                title
                page {
                  slug
                  homePage
                }
              }
            }
          }
        }
      }
    `
  );
  return graphcms.menus;
}
