import React from "react";
import PropTypes from "prop-types";

import { withAdditionalTheme, useStyleProps } from "@flive/react-kit"; // eslint-disable-line import/no-unresolved

import theme from "../theme";
import StyledBrandIcon from "./styled";

/**
 * BrandIcon is the Sigfox logo
 * @group Brand
 * @howtoimport import { Brand } from '@flive/react-kit';
 * Brand.BrandIcon
 * @maturity stable
 */

const BrandIcon = withAdditionalTheme(theme, "brand", (props) => {
  const sizes = useStyleProps({
    path: "common.size",
  });
  const { size, sizeDirection, ratio, ...rest } = useStyleProps({
    path: "brand.brandIcon",
    ...props,
  });
  const pxSize = sizes[size];
  let pxWidth;
  let pxHeight;
  if (sizeDirection === "width") {
    pxWidth = pxSize;
    pxHeight = pxSize / ratio;
  } else {
    pxWidth = pxSize * ratio;
    pxHeight = pxSize;
  }
  return <StyledBrandIcon pxWidth={pxWidth} pxHeight={pxHeight} {...rest} />;
});

BrandIcon.propTypes = {
  /**
   * Use this to resize the icon
   */
  size: PropTypes.string,
  /**
   * As the BrandIcon component is a rectangle, this allows you to define the direction where the size is computed.
   */
  sizeDirection: PropTypes.oneOf(["width", "height"]),
};

BrandIcon.defaultProps = {
  size: "mini-8",
  sizeDirection: "height",
};

BrandIcon.displayName = "Brand.BrandIcon";

/** @component */
export default BrandIcon;
