import React from "react";
import PropTypes from "prop-types";
import {
  withAdditionalTheme,
  useStyleProps,
  Text,
  View,
} from "@flive/react-kit"; // eslint-disable-line import/no-unresolved
import BrandIcon from "@components/Brand/BrandIcon";

import brandTheme from "./theme";

/**
 * @extends View
 * @group Brand
 * @maturity stable
 */
const Brand = withAdditionalTheme(brandTheme, "brand", ({ name, ...rest }) => (
  <View {...useStyleProps({ path: "brand.wrapper", ...rest })}>
    <View
      {...useStyleProps({
        path: "brand.contents",
      })}
    >
      <BrandIcon />
      <View {...useStyleProps({ path: "brand.brand" })}>
        {typeof name === "string" && (
          <View {...useStyleProps({ path: "brand.name" })}>{name}</View>
        )}
      </View>
    </View>
  </View>
));

Brand.propTypes = {
  /**
   * This is the Sigfox product's name
   */
  name: PropTypes.string,
};

Brand.defaultProps = {
  name: null,
};

Brand.displayName = "Brand";

Brand.BrandIcon = BrandIcon;

/** @component */
export default Brand;
