import { graphql, useStaticQuery } from "gatsby";

export default function useMenus() {
  const { graphcms } = useStaticQuery(
    graphql`
      query CompanyInfo {
        graphcms {
          companyInfos {
            address
            phoneNumber
            contactEmail
            siret
            slogan
            name
          }
        }
      }
    `
  );
  return graphcms.companyInfos[0];
}
