// eslint-disable-next-line import/no-unresolved
import icon from "@images/icons/logo-cz.svg";

const brand = {
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  contents: {
    display: "flex",
    flexDirection: "row",
  },
  brand: {
    display: "flex",
    flexDirection: "column",
    margin: { left: "mini-1/2" },
    justifyContent: "center",
  },
  name: {
    textColor: "primaryTitle",
    margin: { top: "mini-1/4" },
    fontFamily: "brand",
    fontSize: "small",
    fontWeight: "light",
  },
  brandIcon: {
    svg: icon,
    ratio: 29 / 24,
    display: "flex",
    alignItems: "center",
  },
};

export default brand;
